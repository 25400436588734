import * as _ from 'lodash';

import {
  HTTPActions,
} from '../Redux/Actions'

import store from '../index';

class API {
  /**
  * @param {string} jwtToken user token
  */
  constructor(jwtToken) {
    this.headers = new Headers();
    if (!_.isEmpty(jwtToken)) {
      this.addToken(jwtToken)
    }
    this.API_URL = 'https://techcell.gramant.ru';
    // this.API_URL = '';
    // this.API_URL = 'http://192.168.99.112:9000';
    // this.API_URL = 'http://192.168.0.109:9000';
    // this.API_URL = 'http://192.168.99.105:9000';
    this.APIVersion = 'v1';
  }



  addToken(token) {
    this.jwtToken = token;
    this.headers.delete('Authorization');
    this.headers.append('Authorization', `jWT ${token}`);
  }

  /**
  * @private
  * @description Convert object to GET parametrs.
  * @param {object} parametrs
  * @returns {string}
  */
  generateGETParametrs(parametrs) {
    let returnedString = '';
    Object.entries(parametrs)
      .forEach((item) => {
        returnedString += `${item[0].toLowerCase()}=${item[1]}&`;
      });
    return returnedString;
  }

  getHost() {
    return this.API_URL;
  }

  /**
  * @private
  * @description Convert object to POST parametrs.
  * @param {object} parametrs
  * @returns {string}
  */

  getError(error) {
    store.dispatch(HTTPActions.RequestError(error))
  }

  generatePOSTParametrs(parametrs) {
    let returnedParametrs;
    if (parametrs instanceof FormData) {
      returnedParametrs = parametrs;
    } else {
      returnedParametrs = JSON.stringify(parametrs);
    }

    return returnedParametrs;
  }

  request(method, path, body) {
    const sendObj = {
      method,
      body: !(method === 'GET' || method === 'DELETE') ? body : null,
      headers: this.headers,
    };
    let query = ''
    if ((method === 'GET' || method === 'DELETE') && body && !_.isEmpty(body)) {
      query = '?' + _.reduce(body, (total, v, k) => {
        total += `${k}=${encodeURIComponent(v)}&`;
        return total;
      }, '')
    };
    const targetURL = `${this.API_URL}/api/${this.APIVersion}/${path}${query}`;
    return fetch(targetURL, sendObj)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        return res.json()
          .then((result) => {
            return Promise.reject({ error: 'httpError', code: res.status, message: result.message })
          })
      })
  };

  auth(body) {
    const sendObj = {
      body,
      method: 'POST',
    };
    const targetURL = `${this.API_URL}/auth/login`;
    return fetch(targetURL, sendObj)
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        }
        return Promise.reject({ error: 'httpError', code: res.status })
      })
  }

  get(path, data) {
    return this.request('GET', path, data)
  }
  post(path, data) {
    return this.request('POST', path, data)
  }

  put(path, data) {
    return this.request('PUT', path, data)
  }

  del(path, data) {
    return this.request('DELETE', path, data)
  }


  test() {
    return new Promise((resolve, reject) => {
      resolve([{ id: 'asd', title: 'privet' }, { id: 'asdcv3', title: 'privet2' }])
    });
  }

  /**
  * @public
  * @description Get sinle techDoc.
  * @param {Number} techdockId Id of selected techdoc.
  * @param {File} model Current model.
  * @returns {Promise}
  */
  UploadModel(techdockId, model) {
    const obj = this;
    return new Promise((resolve, reject) => {
      const URLPath = `${techdockId}/model`;
      const method = 'POST';

      const formData = new FormData();
      formData.append('Model', model);

      obj.sendMessage(URLPath, method, formData)
        .then((value) => {
          resolve(value);
        })
        .catch((error) => {
          reject(error);
        });
    });
  }
}

const api = new API(localStorage.getItem('token'));

export default api;
