import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import { COLORS } from '../../../../Styles/AppColors';

const styles = () => ({
  expanded: {
    borderRight: `1px solid ${COLORS.EXPANSION_BORDER}`,
    borderLeft: `1px solid ${COLORS.EXPANSION_BORDER}`,
    boxShadow: `0px 1px ${COLORS.EXPANSION_BORDER}, 0px -1px ${COLORS.EXPANSION_BORDER}`,
    position: 'relative',
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px',
      borderBottom: `1px solid ${COLORS.EXPANSION_BORDER}`,
      boxShadow: `0px -1px ${COLORS.EXPANSION_BORDER}`,
    },
    '&:first-child': {
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
      borderTop: `1px solid ${COLORS.EXPANSION_BORDER}`,
      boxShadow: `0px 1px ${COLORS.EXPANSION_BORDER}`,
      '&:before': {
        display: 'none',
      },
      '&:last-child': {
        boxShadow: 'none'
      }
    },
    '&:before': {
      backgroundColor: COLORS.EXPANSION_BORDER
    }
  },
  root: {
    border: `1px solid ${COLORS.EXPANSION_BORDER}`,
    borderRadius: '4px 4px 4px 4px',
    boxShadow: 'none',
    '&:first-child': {
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px', 
    }
  },
  isActiveNotOpen: {
    border: `1px solid ${COLORS.BLUE}`,
    boxShadow: 'none',
    zIndex: 1,
    '&:before': {
      display: 'none',
    },
    '&:first-child': {
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px', 
    }
  },
  isActiveOpeen: {
    border: `1px solid ${COLORS.BLUE}`,
    borderRadius: '4px 4px 4px 4px',
    boxShadow: 'none',
    '&:first-child': {
      borderTopRightRadius: '4px',
      borderTopLeftRadius: '4px',
    },
    '&:last-child': {
      borderBottomLeftRadius: '4px',
      borderBottomRightRadius: '4px', 
    }
  }
});

class ArmorMaterialExpansionPanel extends PureComponent {
  componentLifecycle(expanded, isActive){
    const {
      classes
    } = this.props;
    if(expanded && isActive){
      return classes.isActiveOpeen
    }
    if(expanded && !isActive){
      return classes.root
    }
    if(!expanded && isActive){
      return classes.isActiveNotOpen
    }
    if(!expanded && !isActive){
      return classes.expanded
    } 
  }

  render() {
    const {
      expanded,
      isActive
    } = this.props;
    return(
      <ExpansionPanel
        onClick={this.props.Select}
        className={this.componentLifecycle(expanded, isActive)}
        expanded={expanded}
      >
        {this.props.children}
      </ExpansionPanel>
    )
  }
}

ArmorMaterialExpansionPanel.propTypes = {
  classes: PropTypes.object.isRequired,
  expanded: PropTypes.bool,
  isActive: PropTypes.bool
}

export default withStyles(styles)(ArmorMaterialExpansionPanel);
