export { default as ParameterSelection } from './NewTechDocStep/ParameterSelection';

export { default as NewTechDocDescription } from './NewTechDocDescription/NewTechDocDescription';

export { default as Stage } from './Stage/Stage';

export { default as NavigationButtons } from './NavigationButtons';

export { default as TechDocStepper } from './TechDocStepper/TechDocStepper';

export { default as ArmorMaterialsCut } from './Cut/ArmorMaterialsCut';

export { default as Materials } from './Materials/Materials';

export { default as AddingMaterialsDialog } from './Cut/AddingMaterialsDialog/AddingMaterialsDialog';

export { default as ColorPicker } from './Cut/ArmorSurfaces/ColorPicker';

export { default as Editor } from './Cut/Editor/Editor';

export { default as OblationalMaterialsCut } from './Cut/OblationalMaterialsCut';

export { default as ChangingMaterialsDialog } from './Cut/AddingMaterialsDialog/ChangingMaterialsDialog';

export { default as FreeCadCommands } from './FreeCad/FreeCadCommands';

export { default as MyStoreMaterialsTable } from './MyStore/MyStoreMaterialsTable';

export { default as MyStoreChangingMaterialsDialog } from './MyStore/MyStoreChangingMaterialsDialog';

export { default as SubProjectCard } from './ProjectsComponents/SubProjects/SubProjectCard';

export { default as OblationalNotCutMaterials } from './Cut/OblationalNotCutMaterials';

export { default as InReviewTaskCard } from './Analytics/InReviewTaskCard';

export { default as WorkersList } from './Analytics/WorkersList';

export { default as AreYouSureDialog } from './Cut/ArmorSurfaces/AreYouSureDialog';

export { default as PlanningTable } from './Analytics/PlanningTable';

export { default as StatesList } from './Analytics/StatesList'; 

export { default as OverdueCard } from './Analytics/OverdueCard';

export { default as TaskState } from './TaskTemplates/TaskState';

export * from './_NewDesignComponents'